import { z } from "zod";

/**
 * accountRegister
 * {@link https://docs.saleor.io/api-reference/users/mutations/account-register}
 */
export const userRegisterSchema = z
  .object({
    email: z.string().email(),
    password: z.string().min(8),
    password2: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    languageCode: languageCodeSchema.optional(),
  })
  .refine((data) => data.password === data.password2, {
    message: "error.passwordsDoNotMatch",
    path: ["password2"],
  })
  .transform((data) => {
    const { password2, ...rest } = data;
    return rest;
  });

export type RegisterUserSchema = z.infer<typeof userRegisterSchema>;

/**
 * accountUpdate
 * {@link https://docs.saleor.io/api-reference/users/mutations/account-update}
 *
 */
export const userUpdateSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    languageCode: languageCodeSchema,
    metadata: z.array(metadataInputSchema),
  })
  .partial();

export type UpdateUser = z.infer<typeof userUpdateSchema>;

/**
 * tokenCreate
 * {@link https://docs.saleor.io/api-reference/authentication/mutations/token-create}
 */
export const signInSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

export type SignInSchema = z.infer<typeof signInSchema>;

export const requestPasswordResetSchema = z.object({
  email: z.string().email(),
});

export type RequestPasswordResetSchema = z.infer<typeof requestPasswordResetSchema>;

export const setPasswordSchema = z
  .object({
    password: z.string().min(1),
    password2: z.string().min(1),
  })
  .refine((data) => data.password === data.password2, {
    // TODO: i18n
    message: "Passwords do not match",
    path: ["password2"],
  })
  .transform((data) => {
    const { password2, ...rest } = data;
    return rest;
  });

export type SetPasswordSchema = z.infer<typeof setPasswordSchema>;
